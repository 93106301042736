import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '../components/pageMeta';
import { ContactBanner } from '../components/blockZone/blocks';
import BlockZone from '~blockZone';
import Layout from '../components/layout';

const ContactTemplate = ({ data: { page } }) => {
  const { title, bannerImage, bannerText, _rawContactDetails, meta, pageBlocks } = page || {};

  useEffect(() => {
    document.documentElement.classList.add(`theme-dark`);
    return () => {
      document.documentElement.classList.remove(`theme-dark`);
    };
  }, []);

  return (
    <Layout showForm={true}>
      <PageMeta {...meta} />
      <ContactBanner
        title={title}
        image={bannerImage}
        bannerText={bannerText}
        text={_rawContactDetails}
      />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </Layout>
  );
};

export default ContactTemplate;

export const pageQuery = graphql`
  query contactPageQuery($slug: String!) {
    page: sanityContact(slug: { current: { eq: $slug } }) {
      title
      bannerText
      bannerImage {
        ...ImageWithPreview
      }
      _rawContactDetails
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
